import { useState, useEffect, Fragment } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { googleMapURL, markerHotel, markerRestaurant, markerCompany, styleMap, markerUser } from "../../../utils";
import useGeoLocation from "../../../component/molecule/UseGeoLocation";


const MapDetail = ({ map_id }) => {
  const location  = useGeoLocation();
  const { data }  = useSelector((state) => state.GetById);
  //  console.log('LOCATION', location)
  // variable to determine the color according to each category
  const MarkerDetail = () => {
    if (data.catmap_id === "2") {
      return (
        <Marker
          position={{
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          }}
          icon={{
            url: markerHotel,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    } else if (data.catmap_id === "1") {
      return (
        <Marker
          position={{
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          }}
          icon={{
            url: markerRestaurant,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    }  else {
      return (
        <Marker
          position={{
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          }}
          icon={{
            url: markerCompany,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    }
  };

  // variabel map
  const MapWrapped = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }}
        defaultOptions={{
          styles: styleMap,
        }}
      >
        <MarkerDetail />
        <Marker
          position={location}
          icon={{
            url: markerUser,
            scaledSize: new window.google.maps.Size(35, 35),
          }}
        />
      </GoogleMap>
    ))
  );

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MapWrapped
        map_id={map_id}
        googleMapURL={googleMapURL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )

}


export default MapDetail

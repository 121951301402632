import { useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./routeMap.css";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { labelmapLogo } from "../../assets";
import { withGoogleMap, withScriptjs, GoogleMap, DirectionsRenderer, TrafficLayer, TransitLayer, Marker } from "react-google-maps";
import { googleMapURL, styleMap, markerHotel, markerRestaurant, markerCompany, markerUser } from "../../utils";
import useGeoLocation from "../../component/molecule/UseGeoLocation";
import {SearchCompany} from "../../config/Redux/action/getCompany";
import {SearchHotel} from "../../config/Redux/action/getHotel";
import {SearchPlace} from "../../config/Redux/action/getPlace";
import {SearchSPBU} from "../../config/Redux/action/getSPBU";
import { Scrollbars } from "react-custom-scrollbars";
import { CardItem } from "../../component";
import Spinner from 'react-bootstrap/Spinner'
import { GetById } from "../../config/Redux/action/getById";

const RouteMap = () => {
 
  const dispatch                                              = useDispatch();
  const { data }                                              = useSelector((state) => state.GetById);
  const { data: get_data }                                    = useSelector((s) => data.catmap_id == '1' ? s.GetPlace : ( data.catmap_id == '2' ? s.GetHotel : ( data.catmat_id == '3' ? s.GetSPBU : s.GetCompany )));
  // const { data: data_hotel }                                  = useSelector((s) => s.GetHotel);
  // const { data: data_restaurant }                             = useSelector((s) => s.GetPlace);
  const [sidebar, setSidebar]                                 = useState(false);
  // const [showDiv, setShowDiv]                                 = useState();
  const [myLocation, setMyLocation]                           = useState();
  // const [lokasiAwal, setLokasiAwal]                           = useState();
  const showSidebar                                           = () => setSidebar(!sidebar);
  const [styleDefaultRoute, setStyleDefaultRoute]             = useState("btn_option_direc_active text-blue");
  const [styleCarRoute, setStyleCarRoute]                     = useState("btn_option_direc_non_active text-dark");
  const [styleWalkRoute, setStyleWalkRoute]                   = useState("btn_option_direc_non_active text-dark");
  const [styleTransportationRoute, setStyleTransportationRoute]             = useState("btn_option_direc_non_active text-dark");
  const [directionsResponse, setDirectionsResponse]           = useState(null);
  const [distance, setDistance]                               = useState('')
  const [duration, setDuration]                               = useState('')
  const [dnone, setDnone]                                     = useState("row my_location");
  const google = window.google                                = window.google ? window.google : {}
  const [click, setClick]                                     = useState(false)
  const location                                              = useGeoLocation();
  const [searchFirstLocation, setSearchFirstLocation]         = useState("");
  const [searchSecondLocation, setSearchSecondLocation]       = useState("");
  // const [FocusFirstLocation, setFocusFirstLocation]           = useState("");
  // const [FocusSecondLocation, setFocusSecondLocation]         = useState("");
  // const [BlurFirstLocation, setBlurFirstLocation]             = useState("");
  // const [BlurSecondLocation, setBlurSecondLocation]           = useState("");
  const [CompanyNameFirst, setCompanyNameFirst]               = useState();
  const [CompanyName, setCompanyName]                         = useState(data.name);
  const [loading, setLoading]                                 = useState(false);
  const [clickedFirst, setClickedFirst]                       = useState(true)
  const [clicked, setClicked]                                 = useState(true)
  const [latitudeFirstLocation, setLatitudeFirstLocation]     = useState()
  const [longitudeFirstLocation, setLongitudeFirstLocation]   = useState()
  const [latitudeSecondLocation, setLatitudeSecondLocation]   = useState(data.latitude)
  const [longitudeSecondLocation, setLongitudeSecondLocation] = useState(data.longitude)
  const [FirstLocationLength, setFirstLocationLength]         = useState()
  const [SecondLocationLength, setSecondLocationLength]       = useState()
  
  // //SEARCH
  useEffect(() => {
    // setTimeout(() => {
      if (data.catmap_id == '1'){
        dispatch(SearchPlace({ name: searchFirstLocation }));
      }else if(data.catmap_id == '2'){
        dispatch(SearchHotel({ name: searchFirstLocation }));
      }else if(data.catmap_id == '3'){
        dispatch(SearchSPBU({ name: searchFirstLocation }));
      }else{
        dispatch(SearchCompany({ name: searchFirstLocation }));
      }
    // },10000)
  }, [searchFirstLocation]);

  useEffect(() => {
    // setTimeout(() => {
      if (data.catmap_id == '1'){
        dispatch(SearchPlace({ name: searchSecondLocation }));
      }else if(data.catmap_id == '2'){
        dispatch(SearchHotel({ name: searchSecondLocation }));
      }else if(data.catmap_id == '3'){
        dispatch(SearchSPBU({ name: searchSecondLocation }));
      }else{
        dispatch(SearchCompany({ name: searchSecondLocation }));
      }
    // },10000)
  }, [searchSecondLocation]);

  const onClickMyLocation = () => {
    setMyLocation('My Location')
    // console.log('eeeeeeeeeeee',e)
  }

  const _onFocusFirstLocation = () => {
    // setShowDiv(true);
    // setFocusFirstLocation(true)
    setClickedFirst(true);
    // setDnone('row my_location')
    // setStyle("input-company-active");
  };

  const _onBlursFirstLocation = () => {
    // setShowDiv(false);
    // setFocusFirstLocation(false)
    setClicked(false)
    // setDnone('row my_location d-none')
    // setStyle("input-company");
  };

  const _onFocusSecondLocation = () => {
    // setShowDiv(true);
    // setFocusSecondLocation(true)
    setClicked(true);
    // setDnone('row my_location')
    // setStyle("input-company-active");
  };

  const _onBlursSecondLocation = () => {
    // setShowDiv(false);
    // setFocusSecondLocation(false)
    setClickedFirst(false)
    // setDnone('row my_location d-none')
    // setStyle("input-company");
  };

  // layer route map
  const onLoad = trafficLayer => {
    console.log('trafficLayer: ', trafficLayer)
  }

  const onLoadTransit = transitLayer => {
    console.log('transitLayer: ', transitLayer)
  }

  const onLoadWalking = bicyclingLayer => {
    console.log('bicyclingLayer: ', bicyclingLayer)
  }

  const handleClick = () => {
    setClick(!click)
  }

  // function calculate duration & distance route
  const Route = async (route, latitude1, longitude1, latitude2, longitude2) => {
    var directionsService   = new google.maps.DirectionsService();
    var directionsRenderer  = new google.maps.DirectionsRenderer();
    setLoading(true)
    await directionsService.route({
      origin: latitude1 && longitude1 ? {lat: parseFloat(latitude1), lng: parseFloat(longitude1)} : myLocation != '' ? location : '',
      destination: latitude2 && longitude2 ? {lat: parseFloat(latitude2), lng: parseFloat(longitude2)} : {lat: parseFloat(data.latitude), lng: parseFloat(data.longitude)},
      // eslint-disable-next-line no-undef
      travelMode: route === "DRIVING" ? window.google.maps.TravelMode.DRIVING :
                  route === "WALKING" ? window.google.maps.TravelMode.WALKING :
                  route === "TRANSIT" ? window.google.maps.TravelMode.TRANSIT :
                  window.google.maps.TravelMode.DRIVING,
    })
    .then(results => {
      console.log('results.status', results.status)
      console.log('google.maps.DirectionsStatus.OK', google.maps.DirectionsStatus.OK)
      if (results.status === google.maps.DirectionsStatus.OK) {
        if (results) {
            // setDirections(result)
            setDirectionsResponse(results)
            setDistance(results.routes[0].legs[0].distance.text)
            setDuration(results.routes[0].legs[0].duration.text)
            setLoading(false)
        }
      } 
    })
    .catch(err => console.log(err))
  }

  const MarkerDetail = () => {
    if (data.catmap_id === "2") {
      return (
        <Marker
          position={{
            lat: parseFloat(latitudeSecondLocation),
            lng: parseFloat(longitudeSecondLocation),
          }}
          icon={{
            url: markerHotel,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    } else if (data.catmap_id === "1") {
      return (
        <Marker
          position={{
            lat: parseFloat(latitudeSecondLocation),
            lng: parseFloat(longitudeSecondLocation),
          }}
          icon={{
            url: markerRestaurant,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    }  else {
      return (
        <Marker
          position={{
            lat: parseFloat(latitudeSecondLocation),
            lng: parseFloat(longitudeSecondLocation),
          }}
          icon={{
            url: markerCompany,
            scaledSize: new window.google.maps.Size(25, 35),
          }}
        />
      );
    }
  };


  // variabel map
  const MapWrapped = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }}
        defaultOptions={{
          styles: styleMap,
        }}
      >
        <DirectionsRenderer directions={directionsResponse} panel={ document.getElementById('panel') }/>
        {styleDefaultRoute || styleCarRoute ? (
          <TrafficLayer onLoad={onLoad}/>
        ) : styleWalkRoute ? (
          <TrafficLayer onLoad={onLoadWalking}/>
        ) : styleTransportationRoute ? (
          <TransitLayer onLoad={onLoadTransit}/>
        ) : (
          <TrafficLayer onLoad={onLoad}/>
        )}
        <MarkerDetail />
        <Marker
          position={location}
          icon={{
            url: markerUser,
            scaledSize: new window.google.maps.Size(35, 35),
          }}
        />
      </GoogleMap>
    ))
  );
  
  const onChangeFirstLocation = (e) => {
      setFirstLocationLength(e.length)
      setSearchFirstLocation(e)
      setCompanyNameFirst(e)
      setMyLocation(e)
  }

  const onChangeSecondLocation = (e) => {
    setSecondLocationLength(e.length)
      setSearchSecondLocation(e)
      setCompanyName(e)
  }

  const onClickDriving = () => {
    if(CompanyNameFirst == undefined){
      setMyLocation('My Location')
    }
    setStyleDefaultRoute('btn_option_direc_non_active text-dark')
    setStyleCarRoute('btn_option_direc_active text-blue')
    setStyleWalkRoute('btn_option_direc_non_active text-dark')
    setStyleTransportationRoute('btn_option_direc_non_active text-dark')
    Route("DRIVING", latitudeFirstLocation, longitudeFirstLocation, latitudeSecondLocation, longitudeSecondLocation)
  }

  const onClickWalking = () => {
    if(CompanyNameFirst == undefined){
      setMyLocation('My Location')
    }
    setStyleDefaultRoute('btn_option_direc_non_active text-dark')
    setStyleCarRoute('btn_option_direc_non_active text-dark')
    setStyleWalkRoute('btn_option_direc_active text-blue')
    setStyleTransportationRoute('btn_option_direc_non_active text-dark')
    Route("WALKING", latitudeFirstLocation, longitudeFirstLocation, latitudeSecondLocation, longitudeSecondLocation)
  }

  const onClickTransportation = () => {
    if(CompanyNameFirst == undefined){
      setMyLocation('My Location')
    }
    setStyleDefaultRoute('btn_option_direc_non_active text-dark')
    setStyleCarRoute('btn_option_direc_non_active text-dark')
    setStyleWalkRoute('btn_option_direc_non_active text-dark') 
    setStyleTransportationRoute('btn_option_direc_active text-blue')
    Route("TRANSIT", latitudeFirstLocation, longitudeFirstLocation, latitudeSecondLocation, longitudeSecondLocation)
  }

  // console.log('Company Name First', CompanyNameFirst)
  // console.log('Company Name ', CompanyName)
  // console.log('FirstLocationLength',FirstLocationLength)

  // console.log('MY LOCATION', myLocation)
  // console.log('LOKASI AWAL', lokasiAwal)
  // console.log("get_data", get_data)
  // console.log("cek direction", directionsResponse)
  // console.log("by idddd", data.map_id)

  return (
    <>
      <div className="main-page">
        {/* info detail version desktop */}
        <div className="left">
          <div className="sidebardiv">
            <Link to="#" className="menu-bars">
                <Button className="button-rute" onClick={showSidebar}>
                  <i className="fas fa-directions" ></i> 
                </Button> 
            </Link>
            <span style={{ fontSize:13, marginLeft: 8 }}>Route</span>
          </div>
          <nav className="div-menu active shadow">
            <ul className="div-menu-items ml-2">
              
              <li style={{ marginTop: 10, marginBottom: 10 }} >
                <div className="row" style={{ marginLeft:'-4rem' }}>
                  <div className="col-md-3">
                    <Link to="/">
                      <img
                        alt="label map logo"
                        className="mr-1 mb-2"
                        src={labelmapLogo}
                        width={65}
                      /> 
                    </Link>
                  </div>
                  <div className="col-md-1" style={{ marginTop:5, marginRight: 20 }}> 
                    <Button className={styleDefaultRoute} style={{ width:40, borderRadius:'50%',  }} title="Travel mode recomendation" onClick={() => [ setStyleDefaultRoute('btn_option_direc_active text-blue'), setStyleCarRoute('btn_option_direc_non_active text-dark'), setStyleWalkRoute('btn_option_direc_non_active text-dark'), setStyleTransportationRoute('btn_option_direc_non_active text-dark')]}>
                      <i className="fas fa-directions " style={{ fontSize: 20, marginLeft: -3.2 }} ></i>
                    </Button>
                  </div>
                  <div className="col-md-1" style={{ marginTop:5, marginRight: 20 }}> 
                    <Button className={styleCarRoute} style={{ width:40, borderRadius:'50%' }} title="Driving" onClick={() => onClickDriving() }>
                      <i className="fas fa-car " style={{ fontSize: 20, marginLeft: -3.2 }} ></i>
                    </Button>
                  </div>
                  <div className="col-md-1" style={{ marginTop:5, marginRight: 20 }}> 
                    <Button className={styleWalkRoute} style={{ width:40, borderRadius:'50%' }} title="Walking" onClick={() => onClickWalking()}>
                      <i className="fas fa-walking " style={{ fontSize: 20, marginLeft: -2 }} ></i>
                    </Button>
                  </div>
                  <div className="col-md-1" style={{ marginTop:5, marginRight: 20 }}> 
                    <Button className={styleTransportationRoute} style={{ width:40, borderRadius:'50%' }} title="Transportation" onClick={() => onClickTransportation()}>
                      <i className="fas fa-train " style={{ fontSize: 20, marginLeft: -2}} ></i>
                    </Button>
                  </div>
                  {/* <div className="col-md-1" style={{ marginTop:'10px', marginRight: 7 }} onClick={showSidebar}> 
                    <Link to="#">
                      <i className="fas fa-motorcycle text-dark" style={{ fontSize: 20,position:'absolute', right: 0, right: 20 }}></i>
                    </Link>
                  </div> */}
                  <div className="col-md-2" style={{ marginTop:'10px' }} > 
                    <Link to={`/detail_place/${data.map_id}`}>
                      <i className="fas fa-times text-dark" style={{ fontSize: 20,position:'absolute', right: 0, right: 20 }}></i>
                    </Link>
                  </div>
                </div>
              </li>

              {/* <li style={{ marginLeft: -43, marginBottom: 30, marginTop: 20 }}>
              
              </li> */}
              {/* <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li>
              <li style={{ marginLeft: -45, marginBottom: -8 }}>
                <div>lat FIRST: {latitudeFirstLocation}</div>
                <div>lng FIRST: {longitudeFirstLocation}</div>
              </li>
              <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li>
              <li style={{ marginLeft: -45, marginBottom: -8 }}>
                <div>lat SECOND: {latitudeSecondLocation}</div>
                <div>lng SECOND: {longitudeSecondLocation}</div>
              </li>
              <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li> */}

              <li style={{ marginLeft: -33, marginBottom: 20 }}>
                <div className="row">
                  <div className="col-md-1" style={{ background: '#10175c', borderTopRightRadius: 5,borderBottomRightRadius: 5 }}>
                    <i className="fas icon-lokasi-awal">A</i>
                  </div>
                  <div className="col-md-10 ">
                    {/* <input className="form-control" onChange={(e) => SearchPlace({ name: searchFirstLocation })}></input> */}
                    <input className="form-control" placeholder="Choose first location..." onFocus={_onFocusFirstLocation} onBlur={_onBlursFirstLocation} value={myLocation} onChange={(e) => onChangeFirstLocation(e.target.value)}></input>
                    {
                      searchFirstLocation && clickedFirst == true && FirstLocationLength >= 3 ? 
                        <div style={{ border:'0.1px solid #e7e7e7', marginTop:'1ex', position:'absolute', zIndex:2, background:'white', width:'90%', maxHeight:'40vh', overflowY:'scroll' }}>
                          {
                            get_data.map((items) => {
                              // console.log('items name',items.name)
                              return (

                                  <div style={{ border:'0.1px solid #e7e7e7', paddingBottom:'2ex', paddingTop:'2ex'}} onClick={() => [setClickedFirst(false), setLatitudeFirstLocation(items.latitude), setLongitudeFirstLocation(items.longitude), setCompanyNameFirst(items.name), setMyLocation(items.name), Route("DRIVING", items.latitude, items.longitude, latitudeSecondLocation, longitudeSecondLocation)]}>
                                    <CardItem
                                      key={items.map_id}
                                      name={items.name}
                                      location={items.location}
                                      images={items.photo}
                                      rating={items.rating}
                                      facility={items.facility}
                                      map_id={items.map_id}
                                    />
                                  </div>
                              );
                            }) 
                          }
                        </div>
                      
                      : ''
                    }
                  </div>
                </div>
              </li>

              <li style={{ marginLeft: -33, marginBottom: 20 }}>
                <div className="row" >
                  <div className="col-md-1" style={{ background: '#10175c', borderTopRightRadius: 5,borderBottomRightRadius: 5 }}>
                    <i className="fas icon-tujuan">B</i>
                  </div>
                  <div className="col-md-10 ">
                    <input className="form-control" placeholder="Choose direction..." onFocus={_onFocusSecondLocation} onBlur={_onBlursSecondLocation} value={CompanyName} onChange={(e) => onChangeSecondLocation(e.target.value)}></input>
                    {
                      searchSecondLocation && clicked == true && SecondLocationLength >= 3 ? 
                        <div style={{ border:'0.1px solid #e7e7e7', marginTop:'1ex', position:'absolute', zIndex:2, background:'white', width:'90%', maxHeight:'40vh', overflowY:'scroll' }}>
                          {
                            get_data.map((items) => {
                              return (
                                <div style={{ border:'0.1px solid #e7e7e7', paddingBottom:'2ex', paddingTop:'2ex'}} 
                                onClick={() => [setClicked(false), setLatitudeSecondLocation(items.latitude), setLongitudeSecondLocation(items.longitude), setCompanyName(items.name), Route("DRIVING",  latitudeFirstLocation, longitudeFirstLocation, items.latitude, items.longitude)]}>

                                  <CardItem
                                    key={items.map_id}
                                    name={items.name}
                                    location={items.location}
                                    images={items.photo}
                                    rating={items.rating}
                                    facility={items.facility}
                                    map_id={items.map_id}
                                  />
                                </div>
                              );
                            }) 
                          }
                        </div>
                      
                      : ''
                    }
                  </div>
                </div>
              </li>

              {/* {
                showDiv == true ? <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li> : ''         
              } */}
              <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li>

              <li style={{ marginLeft: -33, marginBottom: -8}} 
              // onClick={() => [ setMyLocation('My Location'), setDnone('row my_location d-none'), alert('My Location')]}
              onClick={() => [setMyLocation('My Location'), Route()]}
              >
                <div className={dnone} >
                  <div className="col-md-1" style={{  }}>
                    <i className="fas fa-street-view icon-my-location"></i>
                  </div>
                  <div className="col-md-10 ml-2" style={{ lineHeight:'50px',  }}>
                    My Location
                  </div>
                </div>
              </li>

              {
                distance != '' ?
                <>
                  <li style={{ marginLeft: -45, marginBottom: -11 }}><hr/></li>
                  
                  <li style={{ marginLeft: -45, marginBottom: -11 }}>
                    <div className="row" style={{ fontWeight:'bold', color:'#001C54' }}>
                      <div className="col-md-10 ml-2" style={{ lineHeight:'50px' }}>
                        Direction Details
                      </div>
                    </div>
                  </li>

                  <li style={{ marginLeft: -33, marginBottom: -8}}>
                    <div className="row" style={{ fontSize:12 }}>
                      <div className="col-md-1">
                        <i className="fas fa-route icon-my-location"></i>
                      </div>
                      <div className="col-md-10 ml-2" style={{ lineHeight:'50px' }}>
                        <b>{distance}</b> from your location
                      </div>
                    </div>
                  </li>

                  <li style={{ marginLeft: -33, marginBottom: -8}}>
                    <div className="row" style={{ fontSize:12 }}>
                      <div className="col-md-1">
                        <i className="fas fa-clock icon-my-location"></i>
                      </div>
                      <div className="col-md-10 ml-2" style={{ lineHeight:'50px' }}>
                        {/* <b>1</b> Hour <b>25</b> Minues */}
                        {duration}
                      </div>
                    </div>
                  </li>
                </>
                : ''
              }
                  <li style={{ marginLeft: -45, marginBottom: -8 }}><hr/></li>

                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ): (
                    <div style={{ marginLeft: -33, marginBottom: -8}}>
                      <Scrollbars style={{ height: '45vh'}}>
                        <div id="panel"></div>
                      </Scrollbars>
                    </div>
                  )}
            </ul>
          </nav>
        </div>

        {/* map */}
        <div className="right">
          <div className="maps">
          <div style={{ width: "100vw", height: "100vh" }}>

            <MapWrapped
              map_id={data.map_id}
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />

          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(RouteMap);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    paddingTop: 10,
  },
  preview: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};

import { useState, useEffect, useRef } from "react";
import Carousel from "react-elastic-carousel";
import { Link, useHistory } from "react-router-dom";
import "./welcome.css";
import {
  bg,
  mapsWhite,
  mapsGif,
  quote,
  person,
  labelmapLogo,
  cf1,
  cf2,
  cf3,
  partner,
  ispo,
  iso_13485,
  iso_45001,
  iso_14001,
  iso_22001,
  iso_9001,
  CHSE,
  halal
} from "../../assets";
import Hello from './hello';
import { Gap } from "../../component/atom";
import Select from 'react-select';
import Chart from 'react-apexcharts'
import { useDispatch, useSelector } from "react-redux";
import { TotalPerusahaan } from "../../config/Redux/action/getTotalPerusahaan";
import { TotalPerusahaanPieChart } from "../../config/Redux/action/getTotalPerusahaanPieChart";
import { MasterSertifikat } from "../../config/Redux/action/getMasterSertifikat";
import { MasterProvinsi } from "../../config/Redux/action/getMasterProvinsi";
import { Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { auth } from "../../config/services";
import { logout } from "../../config/services";
import { getAuth, onAuthStateChanged } from "firebase/auth";


const content = [
  {
    body: `Aplikasi ini sangat memudahkan saya dalam memcari perusahan yang sudah memiliki sertifikat ISO !`,
    images: cf1,
    user: `Adit`,
    job: `HRGA Staff`,
  },
  {
    body: `Aplikasi ini hebat! saya bisa tahu mana hotel yang sudah bersertifikasi CHSE dan bintang 4!`,
    images: cf2,
    user: `Kurnia`,
    job: `Finance Staff`,
  },
  {
    body: `Dengan Aplikasi Label Map mempermudah saya menemukan restoran yang halal`,
    images: cf3,
    user: `Fauzan`,
    job: `IT Staff`,
  },
];

const sendMail = () => {
  const mailto = "mailto:info@labelmaps.com";
  window.location.href = mailto;
};

function Welcome() {  

  // CEK PENGGUNA YANG SEDANG LOGIN

  const auth                = getAuth();
  const [idUser, setIduser] = useState('');

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setIduser(uid)
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  // console.log(idUser)

  //////////////////////////////////////////////////////////////////////////////////////////

  const { data:total_perusahaan }             = useSelector((state) => state.GetTotalPerusahaan); //FROM REDUCERS
  const { data:master_sertifikat }            = useSelector((state) => state.GetMasterSertifikat);
  const { data:master_provinsi }              = useSelector((state) => state.GetMasterProvinsi);
  const { data:total_perusahaan_pie_chart }   = useSelector((state) => state.GetTotalPerusahaanPieChart); //FROM REDUCERS
    
  const dispatch                = useDispatch();
  const history                 = useHistory();
  


  useEffect(() => {
    dispatch(TotalPerusahaan({iso_id: selectedOption}));
    dispatch(TotalPerusahaanPieChart({id_provinsi: selectedOptionProvPieChart, iso_id: selectedOptionIsoIdPieChart}));
    dispatch(MasterSertifikat());
    dispatch(MasterProvinsi());
  }, []);

  async function handleLogOut() {
    try{
      await logout();
      history.push("/")   
      window.location.reload(); 
    } catch {
      alert("error")
    }
  }

  /////////////////////////////////////////// BAR CHART

  var dataNamaProvinsi                            = '';
  var dataTotalPerusahaan                         = '';
  var dataTotalPerusahaanTersertifikasiAktif      = '';
  var dataTotalPerusahaanTersertifikasiTidakAktif = '';
  var dataTotalPerusahaanBelumBersertifikat       = '';

  {
    total_perusahaan && total_perusahaan.map((value, i) => {
      dataNamaProvinsi                                += value.nama_provinsi+ ','; 
      dataTotalPerusahaan                             += value.total_perusahaan+ ','; 
      dataTotalPerusahaanTersertifikasiAktif          += value.total_perusahaan_tersertifikasi_aktif+ ','; 
      dataTotalPerusahaanTersertifikasiTidakAktif     += value.total_perusahaan_tersertifikasi_tidak_aktif+ ','; 
      dataTotalPerusahaanBelumBersertifikat           += value.total_perusahaan_belum_bersertifikat+ ','; 
    })
  }

  dataNamaProvinsi                                = [dataNamaProvinsi.split(',')];
  dataTotalPerusahaan                             = [dataTotalPerusahaan.split(',').map(Number)];
  dataTotalPerusahaanTersertifikasiAktif          = [dataTotalPerusahaanTersertifikasiAktif.split(',').map(Number)];
  dataTotalPerusahaanTersertifikasiTidakAktif     = [dataTotalPerusahaanTersertifikasiTidakAktif.split(',').map(Number)];
  dataTotalPerusahaanBelumBersertifikat           = [dataTotalPerusahaanBelumBersertifikat.split(',').map(Number)];

  // console.log('Nama Provinsi : ', dataNamaProvinsi[0])
  // console.log('Total Perusahaan : ', dataTotalPerusahaan[0])
  // console.log('Total Perusahaan Tersertifikasi Aktif : ', dataTotalPerusahaanTersertifikasiAktif[0])
  // console.log('Total Perusahaan Tersertifikasi Tidak Aktif : ', dataTotalPerusahaanTersertifikasiTidakAktif[0])
  // console.log('Total Perusahaan Belum Bersertifikat: ', dataTotalPerusahaanBelumBersertifikat[0])
  
  const series = [
    {
      name: 'Total Perusahaan',
      data: 
       [137,
        324,
        56 ,
        280,
        164,
        170,
        54 ,
        81 ,
        53 ,
        3  ,
        0  ,
        7  ,
        0  ,
        0  ,
        0  ,
        5  ,
        0  ,
        0  ,
        0  ,
        349,
        203,
        90 ,
        318,
        64 ,
        0  ,
        19 ,
        8  ,
        20 ,
        4  ,
        14 ,
        9  ,
        1  ,
        10 ,
        23 ]
    }, 
    {
      name: 'Perusahaan Tersertifikasi Aktif',
      data: dataTotalPerusahaanTersertifikasiAktif[0]
    }, {
      name: 'Perusahaan Tersertifikasi Tidak Aktif (Expired)',
      data: dataTotalPerusahaanTersertifikasiTidakAktif[0]
    },
    {
      name: 'Perusahaan Belum Tersertifikasi',
      // data: dataTotalPerusahaanBelumBersertifikat[0]
      data : [137-(dataTotalPerusahaanTersertifikasiAktif[0][0] +dataTotalPerusahaanTersertifikasiTidakAktif[0][0] ),
              324-(dataTotalPerusahaanTersertifikasiAktif[0][1] +dataTotalPerusahaanTersertifikasiTidakAktif[0][1] ),
              56 -(dataTotalPerusahaanTersertifikasiAktif[0][2] +dataTotalPerusahaanTersertifikasiTidakAktif[0][2] ),
              280-(dataTotalPerusahaanTersertifikasiAktif[0][3] +dataTotalPerusahaanTersertifikasiTidakAktif[0][3] ),
              164-(dataTotalPerusahaanTersertifikasiAktif[0][4] +dataTotalPerusahaanTersertifikasiTidakAktif[0][4] ),
              170-(dataTotalPerusahaanTersertifikasiAktif[0][5] +dataTotalPerusahaanTersertifikasiTidakAktif[0][5] ),
              54 -(dataTotalPerusahaanTersertifikasiAktif[0][6] +dataTotalPerusahaanTersertifikasiTidakAktif[0][6] ),
              81 -(dataTotalPerusahaanTersertifikasiAktif[0][7] +dataTotalPerusahaanTersertifikasiTidakAktif[0][7] ),
              53 -(dataTotalPerusahaanTersertifikasiAktif[0][8] +dataTotalPerusahaanTersertifikasiTidakAktif[0][8] ),
              3  -(dataTotalPerusahaanTersertifikasiAktif[0][9] +dataTotalPerusahaanTersertifikasiTidakAktif[0][9] ),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][10]+dataTotalPerusahaanTersertifikasiTidakAktif[0][10]),
              7  -(dataTotalPerusahaanTersertifikasiAktif[0][11]+dataTotalPerusahaanTersertifikasiTidakAktif[0][11]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][12]+dataTotalPerusahaanTersertifikasiTidakAktif[0][12]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][13]+dataTotalPerusahaanTersertifikasiTidakAktif[0][13]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][14]+dataTotalPerusahaanTersertifikasiTidakAktif[0][14]),
              5  -(dataTotalPerusahaanTersertifikasiAktif[0][15]+dataTotalPerusahaanTersertifikasiTidakAktif[0][15]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][16]+dataTotalPerusahaanTersertifikasiTidakAktif[0][16]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][17]+dataTotalPerusahaanTersertifikasiTidakAktif[0][17]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][18]+dataTotalPerusahaanTersertifikasiTidakAktif[0][18]),
              349-(dataTotalPerusahaanTersertifikasiAktif[0][19]+dataTotalPerusahaanTersertifikasiTidakAktif[0][19]),
              203-(dataTotalPerusahaanTersertifikasiAktif[0][20]+dataTotalPerusahaanTersertifikasiTidakAktif[0][20]),
              90 -(dataTotalPerusahaanTersertifikasiAktif[0][21]+dataTotalPerusahaanTersertifikasiTidakAktif[0][21]),
              318-(dataTotalPerusahaanTersertifikasiAktif[0][22]+dataTotalPerusahaanTersertifikasiTidakAktif[0][22]),
              64 -(dataTotalPerusahaanTersertifikasiAktif[0][23]+dataTotalPerusahaanTersertifikasiTidakAktif[0][23]),
              0  -(dataTotalPerusahaanTersertifikasiAktif[0][24]+dataTotalPerusahaanTersertifikasiTidakAktif[0][24]),
              19 -(dataTotalPerusahaanTersertifikasiAktif[0][25]+dataTotalPerusahaanTersertifikasiTidakAktif[0][25]),
              8  -(dataTotalPerusahaanTersertifikasiAktif[0][26]+dataTotalPerusahaanTersertifikasiTidakAktif[0][26]),
              20 -(dataTotalPerusahaanTersertifikasiAktif[0][27]+dataTotalPerusahaanTersertifikasiTidakAktif[0][27]),
              4  -(dataTotalPerusahaanTersertifikasiAktif[0][28]+dataTotalPerusahaanTersertifikasiTidakAktif[0][28]),
              14 -(dataTotalPerusahaanTersertifikasiAktif[0][29]+dataTotalPerusahaanTersertifikasiTidakAktif[0][29]),
              9  -(dataTotalPerusahaanTersertifikasiAktif[0][30]+dataTotalPerusahaanTersertifikasiTidakAktif[0][30]),
              1  -(dataTotalPerusahaanTersertifikasiAktif[0][31]+dataTotalPerusahaanTersertifikasiTidakAktif[0][31]),
              10 -(dataTotalPerusahaanTersertifikasiAktif[0][33]+dataTotalPerusahaanTersertifikasiTidakAktif[0][33]),
              23 -(dataTotalPerusahaanTersertifikasiAktif[0][32]+dataTotalPerusahaanTersertifikasiTidakAktif[0][32])]
    }
  ];
  
  const options = {
    chart: {
      type: 'bar',
      height: 200
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dataNamaProvinsi[0],
    },
    yaxis: {
      title: {
        text: 'Jumlah Perusahaan'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Perusahaan"
        }
      }
    }
  }

  ////////////////////////// FILTER BAR CHART BERDASARKAN SERTIFIKAT

  const options_select = [];
  master_sertifikat && master_sertifikat.map((value, i) => { 
    options_select.push(
      { value: value.iso_id, label: value.iso_name },
    )
  })

  const getInitialStates = () => {
    const valueSelectedOption = "10";
    return valueSelectedOption;
  };

  const [selectedOption, setSelectedOption] = useState(getInitialStates);

  const handleChanges = (selectedOption) => {
    setSelectedOption({selectedOption});
    dispatch(TotalPerusahaan({iso_id: selectedOption.value}));
    // console.log(`Option selected:`, selectedOption.label);
    // console.log('Total Perusahaan BAR CHART : ', dataTotalPerusahaan[0])

  };



  ///////////////////////////////////// PIE CHART

  var totalPerusahaanPieChart                         = '';
  var totalPerusahaanTersertifikasiAktifPieChart      = '';
  var totalPerusahaanTersertifikasiTidakAktifPieChart = '';

  {
    total_perusahaan_pie_chart && total_perusahaan_pie_chart.map((value, i) => {
      totalPerusahaanPieChart                            = Number(value.total_perusahaan_perprovinsi); 
      totalPerusahaanTersertifikasiAktifPieChart         = Number(value.total_perusahaan_tersertifikasi_aktif); 
      totalPerusahaanTersertifikasiTidakAktifPieChart    = Number(value.total_perusahaan_tersertifikasi_tidak_aktif); 
    })
  }

  // console.log('totalPerusahaanPieChart',totalPerusahaanPieChart)
  // console.log('totalPerusahaanTersertifikasiAktifPieChart',totalPerusahaanTersertifikasiAktifPieChart)
  // console.log('totalPerusahaanTersertifikasiTidakAktifPieChart',totalPerusahaanTersertifikasiTidakAktifPieChart)
  
  
  const seriesPie  = [totalPerusahaanPieChart, totalPerusahaanTersertifikasiAktifPieChart, totalPerusahaanTersertifikasiTidakAktifPieChart, (totalPerusahaanPieChart - (totalPerusahaanTersertifikasiAktifPieChart + totalPerusahaanTersertifikasiTidakAktifPieChart))]

  const optionsPie = {
    chart: {
      width: 380,
      type: 'pie',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      }
    },
    labels: ['Jumlah Perusahaan', 'Tersertifikat Aktif', 'Tersertifikasi Tidak Aktif (Expired)','Belum Tersertifikasi'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 380
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }

  ////////////////////////// FILTER PROVINSI PIE CHART BERDASARKAN SERTIFIKAT
  
  const options_select_prov_pie_chart = [];
  master_provinsi && master_provinsi.map((value, i) => { 
    options_select_prov_pie_chart.push(
      { value: value.id_provinsi, label: value.nama_provinsi },
    )
  })

  const getInitialStatesProvPieChart = () => {
    const valueSelectedOptionProvPieChart = "1";
    return valueSelectedOptionProvPieChart;
  };

  const [selectedOptionProvPieChart, setSelectedOptionProvPieChart] = useState(getInitialStatesProvPieChart);

  const handleChangesProvPieChart = (e) => {
    setSelectedOptionProvPieChart(e.value);
    dispatch(TotalPerusahaanPieChart({id_provinsi: e.value, iso_id: selectedOptionIsoIdPieChart}));
    // console.log(`Option selected:`, e.label);
    // console.log(`Option selected ISO:`, selectedOptionIsoIdPieChart.value);

  };

  ////////////////////////// FILTER SERTIFIKAT PIE CHART BERDASARKAN SERTIFIKAT

  const options_select_sert_pie_chart = [];
  master_sertifikat && master_sertifikat.map((value, i) => { 
    options_select_sert_pie_chart.push(
      { value: value.iso_id, label: value.iso_name },
    )
  })

  const getInitialStatesIsoIdPieChart = () => {
    const valueSelectedOptionIsoIdPieChart = "10";
    return valueSelectedOptionIsoIdPieChart;
  };

  const [selectedOptionIsoIdPieChart, setSelectedOptionIsoIdPieChart] = useState(getInitialStatesIsoIdPieChart);

  const handleChangesIsoIdPieChart = (e) => {
    setSelectedOptionIsoIdPieChart(e.value);
    dispatch(TotalPerusahaanPieChart({id_provinsi: selectedOptionProvPieChart, iso_id: e.value}));
    // console.log(`Option selected:`, e.label);
  };
  

  return (
    <>
      <div className="container-fluid" style={{ overflow: 'hidden' }}>
        <div className="d-flex justify-content-between my-2">
          {/* <div className="row"> */}
            <div className="col-6 px-0" style={{ display:'flex' }}>
              <img alt="logo label maps"
                className="logo-labelmpas"
                src={labelmapLogo}
                width={85}
                style={{ marginLeft: '-17px' }}
              />
              <span className="title-labelmaps" style={{ fontWeight: 800,marginLeft: '-4px' }}>
                Label Maps
              </span>
            </div>
            {/* <div className="btn-l">
              <span className="mr-3">Login</span>
            </div> */}
            <div className="col-6 text-center px-0 pt-1" style={{ display: idUser == '' ? '' : 'none'  }} >
              <div className="getLabel mr-0" >
                <Link to="/login">
                  <p className="title-login text-white">Login</p>
                </Link>
              </div>
            </div>

            <div className="col-6 text-center px-0 pt-1" style={{ display: idUser == '' ? 'none' : ''  }} >
              <div className="getLabel mr-0">
                <div onClick={handleLogOut}  style={{display: "flex", marginLeft: 10, marginRight: 15, bottom: 0, position: "relative", cursor: "pointer", color:'white', fontSize: 15}}>
                  <i className="fas fa-sign-out-alt pr-2" style={{marginTop: 0, fontSize: 20, alignItems: 'center', display: 'grid',}}/>
                  <p className="title-login text-white" style={{marginLeft: 3}}>Logout</p>
                </div>
              </div>
            </div>

          {/* </div> */}
        </div>

        <div className="row">
          <div className="col-md">
            <div className="bg-image">
              <div className="txt-bg-image text-center pr-4" style={{ zIndex:2, width: '100%' }}><p style={{ opacity:0.6 }}>Come on, label your place</p>
                  {/* <img className="" src={mapsGif} alt={"maps"} width={250} height={200}/> */}
                <Link to="/company"   >

                  <button className="btn-getstarted" style={{ background:'#001c54', color:'white', width: '135px', height: '10ex', borderRadius: '21px'}}>
                    <img className="" src={mapsGif} alt={"maps"} width={25} height={25} style={{ marginTop: '-11px', marginRight: '10px' }}/>
                    Open Map
                  </button>
                </Link>
              </div>
              <img
                src={bg}
                width={"100%"}
                alt="background"
                className="img-bg"
              />
              
            </div>
          </div>
        </div>
        {/* <div className="bgImg">
          <div className="mt-2" style={{marginTop: 30}}>
          <p className="title-bg">Come on, label your place</p>
          <img
            src={mapsWhite}
            width={"100%"}
            alt={"maps"}
            className="img-bg"
          />
          <Link to="/company"  className="btn-open-maps">
            <div className="d-flex wrap-btn-open-map">
              <img className="mt-1" src={mapsWhite} alt={"maps"} width={25} height={25}/>
              <p className="text-white ml-1 mt-1" >Open Map</p>
            </div>
          </Link>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md">
            <div className="background-landing">
              <div className="text-background">
                Whatever your business activity, restart operations as quickly
                as possible with appropriate Health, Safety and Hygiene
                conditions.
              </div>
              <div className="button-background">
                <div>
                  <button className="btn-getstarted" onClick={sendMail}>
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------------------- MAPS CHART INDONESIA -----------------------------------------------------*/}

        <div className="row">
          <div className="col-md d-flex flex-wrap justify-content-center p-4">
            <div className="text-landing-grafik">
              Indonesia 
              <span className="font-weight-bold ml-1">Sustainable Certificate Index </span>
            </div>
          </div>
        </div>
        
        <Hello name="CodeSandbox" />

        <div style={{ cursor:'pointer' }}>
            
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="font-weight-bold" style={{ color: '#001c54', fontSize: '16px !important', fontFamily: "Poppins" }}>
                        For More Information in Chart
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {/* ------------------------------------- BAR CHART INDONESIA -----------------------------------------------------*/}

                          <div className="row">
                            <div className="col-md d-flex flex-wrap justify-content-center p-4">
                              <div className="text-landing-grafik">
                                <span className="font-weight-bold ml-1">Certified </span>
                                Company Chart
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md d-flex flex-wrap justify-content-center px-4">
                              <Select
                                defaultValue={{ label: "ISPO", value: 10 }}
                                value={selectedOption.label}
                                onChange={handleChanges}
                                options={options_select}
                                className="filter_sert"
                              />
                            </div>
                          </div>

                          <div id="chart" style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }} >
                            <Chart options={options} series={series} type="bar" height={420} style={{width: '1000ex'}}/>
                          </div>

                          {/* ------------------------------------- PIE CHART INDONESIA -----------------------------------------------------*/}

                          {/* <div className="row">
                            <div className="col-md d-flex flex-wrap justify-content-center p-4">
                              <div className="text-landing-grafik">
                                <span className="font-weight-bold ml-1">Certified </span>
                                Company Chart
                                Per Province
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md d-flex flex-wrap justify-content-end px-4 pb-2">
                              <Select
                                defaultValue={{ label: "ACEH", value: 1 }}
                                value={selectedOptionProvPieChart.label}
                                onChange={handleChangesProvPieChart}
                                options={options_select_prov_pie_chart}
                                className="filter_sert_pie"
                              />
                            </div>
                            <div className="col-md d-flex flex-wrap px-4 pb-2">
                              <Select
                                defaultValue={{ label: "ISPO", value: 10 }}
                                value={selectedOptionIsoIdPieChart.label}
                                onChange={handleChangesIsoIdPieChart}
                                options={options_select_sert_pie_chart}
                                className="filter_sert_pie"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md d-flex flex-wrap justify-content-center p-5">
                              <Chart options={optionsPie} series={seriesPie} type="pie" height={420} width={700}/>
                            </div>
                          </div> */}

                          {/* -------------------------------------------------------------------------------------------------------------------- */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>

       

        {/* <div className="row">
          <div className="col-md">
            <div className="background-place">
              <div className="d-flex flex-wrap justify-content-center">
                <img
                  src={ispo}
                  alt="parrtner1"
                  className="sponsor-mcd my-4 m-4"
                />
                <img
                  src={iso_13485}
                  alt="parrtner2"
                  className="sponsor-pertamina my-4 m-4"
                />
                <img
                  src={iso_45001}
                  alt="parrtner3"
                  className="sponsor-garuda my-4 m-4"
                />
                <img
                  src={iso_14001}
                  alt="parrtner4"
                  className="sponsor-santika my-4 m-4"
                />
                <img
                  src={iso_22001}
                  alt="parrtner4"
                  className="sponsor-santika my-4 m-4"
                />
                <img
                  src={iso_9001}
                  alt="parrtner4"
                  className="sponsor-santika my-4 m-4"
                />
                <img
                  src={halal}
                  alt="parrtner4"
                  className="sponsor-santika my-4 m-4"
                />
                <img
                  src={CHSE}
                  alt="parrtner4"
                  className="sponsor-santika my-4 m-4"
                  style={{ width:'150px' }}
                />
                
              </div>
              <div className="partner">
                <img src={partner}/>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md d-flex flex-wrap justify-content-center p-4">
            <div className="text-landing">
              4 Priority
              <span className="font-weight-bold text-landing2">Values</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex flex-wrap justify-content-center pb-4">
            <div className="shape m-4 text-white ">
              Labelling your place
            </div>
            <div className="shape m-4 text-white">
              Provide certainty to the end consumer
            </div>
            <div className="shape m-4 text-white">
              Clear, Informative and Attractive
            </div>
            <div className="shape m-4 text-white">
              To communicate spatial information effectively
            </div>
          </div>
        </div>
        <div className="customer-feedback-wrapper">
          <h6 className="feedback-title">Customer Feedback</h6>
          <div className="feedback-card px-4 py-4">
            <Gap height={15} />
            <Carousel>
              {content.map((item) => {
                return (
                  <>
                    <div style={{ display: "block" }}>
                      <div className="img-quote">
                        <img src={quote} alt="quote" />
                      </div>
                      <div className="text-description text-center">{item.body}</div>
                      <div className="profile mt-4">
                        <img
                          className="img-profile"
                          src={item.images}
                          alt="profile"
                        />
                      </div>
                      <div className="user-name">{item.user}</div>
                      <div className="user-job">{item.job}</div>
                    </div>
                  </>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center p-5">
              <div className="font-weight-bold text-workflow">Workflow</div>
              <div className="p-3 text-workflow2">
                This workflow calculates the route from the current location, a
                contact from your address book or a specified location to either
                the current location, a contact from your address book or a
                specified location.
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 p-5 bg-white rounded">
            <div className="btnstep">
              <button type="button" className="btn-landing1 mb-3">
                STEP 1
              </button>
            </div>
            <div className="text-step">
              Define the list of control points ( Heading )
            </div>
            <div className="text-step">
              You can either use label maps ’ready-to-use checklists or
              customize them with the support of our specialists according to
              your specific needs.
            </div>
          </div>
          <div className="col-md-4 p-5 shadow-lg bg-white rounded">
            <div>
              <button type="button" className="btn-landing2 mb-3">
                STEP 2
              </button>
            </div>
            <div className="text-step">Conduct the audit ( Heading )</div>
            <div className="text-step">
              Global coverage with possibility to conduct both remote audits
              and/or field audits, thanks to our best-in-class digital
              solutions.
            </div>
          </div>
          <div className="col-md-4 p-5 bg-white rounded">
            <div>
              <button type="button" className="btn-landing1 mb-3">
                STEP 3
              </button>
            </div>
            <div className="text-step">Grant the label ( Heading )</div>
            <div className="text-step">
              Possibility to use Label maps standard label or to design your own
              label with a dedicated branding (in accordance with label maps).
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 img-person">
            <img src={person} alt="person" className="img-person" />
          </div>
          <div className="col-md-6">
            <div className="input shadow-lg p-5">
              <div className="text-send">Send Us Message</div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Your Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder="Your Name"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPhoneNumber1"
                  placeholder="Phone Number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputSubject1"
                  placeholder="Subject"
                />
              </div>

              <label className="text-label">Your Message</label>
              <textarea
                style={{ width: "100%" }}
                id="w3review"
                rows="4"
              ></textarea>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  <small className="text-checkbox">
                    By sending this message, you confirm that you have read and
                    agreed to our privacy-policy.
                  </small>
                </label>
              </div>
              <button type="submit" className="btn btnMessage">
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row " style={{ backgroundColor: "#001c54" }}>
            <div className="col-md-5 p-5">
              <div className="text-white">
                <p className="textfooter">
                  Whatever your business activity, restart operations as quickly
                  as possible with appropriate Health, Safety and Hygiene
                  conditions.
                </p>
              </div>
              <div className="">
                <section className="">
                  <Link
                    to="#!"
                    className="btn btn-outline-light btn-floating m-1"
                    role="button"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    to="#!"
                    className="btn btn-outline-light btn-floating m-1"
                    role="button"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link
                    to="#!"
                    className="btn btn-outline-light btn-floating m-1"
                    role="button"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </section>
              </div>
            </div>
            <div className="col-md-3 p-5">
              <div></div>
              <div
                className="text-white textfooter"
                style={{ fontWeight: 800 }}
              >
                Our Links
              </div>
              <div className="text-step">
                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="/#!" className="text-white textfooter">
                      Terms & Condition
                    </Link>
                  </li>
                  <li>
                    <Link to="/#!" className="text-white textfooter">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/#!" className="text-white textfooter">
                      Imprint
                    </Link>
                  </li>
                  <li>
                    <Link to="/#!" className="text-white textfooter">
                      Legal
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 p-5">
              <div></div>
              <div
                className="text-white textfooter"
                style={{ fontWeight: 800 }}
              >
                Contact Us
              </div>
              <div className="text-step">
                <ul className="list-unstyled">
                  <li className="d-flex">
                    <div style={{ marginRight: 10 }}>
                      <i
                        style={{ color: "#21B3FF" }}
                        className="fas fa-map-marker-alt"
                      ></i>
                    </div>
                    <div className="text-white textfooter">
                      Ruko pondok cabe Mutiara Blok B16 Tangerang Selatan
                    </div>
                  </li>
                  <li className="d-flex">
                    <div style={{ marginRight: 10 }}>
                      <i
                        style={{ color: "#21B3FF" }}
                        className="fa fa-phone"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="text-white textfooter">+6221 7447 5110</div>
                  </li>
                  <li className="d-flex">
                    <div style={{ marginRight: 10 }}>
                      <i
                        style={{ color: "#21B3FF" }}
                        className="far fa-envelope"
                      ></i>
                    </div>
                    <div className="text-white textfooter">
                      info@labelmaps.com
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;

import {useState, useEffect} from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {Link, useHistory } from "react-router-dom";
import { useMedia } from "use-media";
import Swal from 'sweetalert2';
import { TextFields } from "../../component/molecule/TextField";
import { Modal } from "react-bootstrap";
import { labelmapLogo, google } from "../../assets";
import { auth, loginWithGoogle, login, resetPassword } from "../../config/services";
import "./login.css";


const Login = () => {
  const [modal, setModal]           = useState(false)
  const [ loading, setLoading ]     = useState(false);
  const [show, setShow]             = useState(false);
  const [emailReset, setEmailReset] = useState("");
  const mobileView                  = useMedia({ maxWidth: "767px" })
  const history                     = useHistory();
  const handleClose                 = () => setShow(false);
  const handleShow                  = () => setShow(true);

  //form validation
  const validate = Yup.object({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 charaters')
      .required('Password is required'),
  })

  //mobile responsive
  useEffect(() => {
    if (window.innerWidth <= 767) return history.replace("/m/login")
  },[])

  //mobile responsive
  useEffect(() => {
    if (!modal) setModal(mobileView)
  }, [mobileView])

  //sign in / sign up with google 
  async function handleLoginWithGoogle(){
    setLoading(true);
    try {
      await loginWithGoogle();
      history.push("/company")
    } catch(err) {
      console.log(err);
    }
    setLoading(false);
  }

  //forgot password
  async function handleResetPassword() {
    setLoading(true);
    try {
      await resetPassword(emailReset);
      Swal.fire({
        icon: 'success',
        title: 'reset password success',
        text: 'please check your email!'
      })
    } catch {
      console.log("Error!"); 
    }
    setLoading(false);
  }

  return (
    <>
      <div className="bg-map-signup">
        <div className="d-flex justify-content-around">
          <Link to="/">
            <img alt="logo label maps"
              className="logo-label-maps-signup"
              src={labelmapLogo}
            />
          </Link>
          <div className="row">
            <p className="account-signup">Don't have an Account ?</p>
            <Link to="/register">
              <p className="create-account-signup">Create Account</p>
            </Link>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="wrap-signup">
            <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validate}
            onSubmit={async values => {
              // console.log(values.email, 'valllll')
              setLoading(true);
              await login(values.email, values.password);
              if(auth.currentUser.emailVerified == true) {
                history.push("/company")
              } else {
                Swal.fire({
                  icon: 'error',
                  text: 'please check your email to verify',
                })
                history.push("/login")
              } 
            }}
            
          >
            {formik => (
              <div >
                <p className="title-signup-page" >Sign In to Label Maps</p>
                <div className="d-flex justify-content-center">
                  <div onClick={handleLoginWithGoogle} disabled={loading} className="row signupGoogle d-flex justify-content-center">
                    <img className="logo-google" src={google}/>
                    <p className="title-signupGoogle">Sign In with Google</p>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-3 line-signup"></div>
                  <p className="col-6 title-option-signup text-center ">or Sign In with Email</p>
                  <div className="col-3 line-signup"></div>
                </div>
                <Form >
                  <TextFields label="Email Address" name="email" type="email" placeholder="Enter your email"/>
                  <div className="d-flex justify-content-between">
                    <label>Password</label>
                    <label onClick={handleShow} className="text-primary">Forgot Password ?</label>
                  </div>
                  <TextFields name="password" type="password" placeholder="Enter your password"/>
                  <button 
                    className="btn-signup" 
                    type="submit">
                      Sign In
                  </button>
                </Form>
                <Modal
                  show={show}
                  onHide={handleClose}
                  animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title className="title-modal-forgot-pass">Forgot Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                    <label className="label-input">Email Address</label>
                      <input
                        className="text-field mb-3"
                        onChange={(e) => setEmailReset(e.target.value)}
                        autoComplete="off"
                        placeholder="Enter your email address"
                      />
                      <button 
                        onClick={handleResetPassword}
                        className="btn-signup" 
                        type="submit">
                          Submit
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )

}

export default Login